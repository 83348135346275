/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ReceivedDocuments from "./ReceivedDocuments";
import RequestedDocuments from "./RequestedDocuments";
import { useDispatch } from "react-redux";
import { SetloaderData } from "../../../store/reducer";
import { apiURl } from "../../../store/actions";
import { API } from "../../../apiwrapper";
import { BASECONFIG } from "../../../config";
import { toast } from "react-toastify";

function NewDocumentManagement() {
  const [activeTab, setActiveTab] = useState("RECEIVED");
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);

  const [allProjects, setAllProjects] = useState([]);

  const fetchProjects = async () => {
    try {
      dispatch(SetloaderData(true));
      let url = `${apiURl.documentMasters}/projects`;
      await API({
        base_url: BASECONFIG.REACT_APP_BASEURL_V2,
        url: `${url}`,
        method: "GET",
        dispatch,
      }).then((data) => {
        if (data?.status || data?.status === "true") {
          setAllProjects(data?.getProjectData);
        } else {
          setAllProjects([]);
        }
      });
    } catch (error) {
      toast.error(error);
    } finally {
      dispatch(SetloaderData(false));
    }
  };
  const handleChangeTab = (type) => {
    setActiveTab(type);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      if (activeTab === "RECEIVED") {
        setActiveTab(activeTab);
      }
    }
  }, [isMounted, activeTab]);

  useEffect(() => {
    // fetchProjects();
  }, []);
  return (
    <>
      <section className="myDashBoard detailTabDashboard">
        <div className="container">
          <div className="row my-5" id="table-contextual">
            <div className="col-md-12">
              <div className="card" style={{ border: "none" }}>
                <div className="card-header">
                  <div className="row align-items-center py-1">
                    <div className="col-md-4">
                      <h4 className="card-title m-0">Document Management</h4>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <div className="row p-2 mt-3 ">
                    <Tabs
                      activeKey={activeTab} // Bind the active tab state
                      onSelect={(k) => handleChangeTab(k)} // Trigger on tab change
                      id="uncontrolled-tab-example"
                      className="mb-0 docomentTabResponsive"
                    >
                      <Tab eventKey="RECEIVED" title="Received Documents">
                        {activeTab === "RECEIVED" && (
                          <ReceivedDocuments activeTab={activeTab}/>
                        )}
                      </Tab>
                      <Tab eventKey="REQUESTED" title="Requested Documents">
                        {activeTab === "REQUESTED" && <ReceivedDocuments activeTab={activeTab}/>}
                      </Tab>
                      <Tab eventKey="MYDOC" title="My Documents">
                        {activeTab === "MYDOC" && <ReceivedDocuments activeTab={activeTab} />}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewDocumentManagement;
