/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowDown, FaArrowUp, FaFileSignature } from "react-icons/fa";
import Select from "react-select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { apiURl } from "../../../store/actions";
import { API, makeSearchString, sendDocSignRequest } from "../../../apiwrapper";
import { SetloaderData } from "../../../store/reducer";
import { BASECONFIG } from "../../../config";
import { toast } from "react-toastify";
import AllProjectsList from "../../Modal/AllProjectsList";
import { truncateSlice } from "../../../Validation/InputValidation";
import AddCommentPopup from "./AddCommentPopup";
import { BsThreeDotsVertical } from "react-icons/bs";
import AllDocumentList from "../../Modal/AllDocumentList";
import { Pagination } from "../../Pagination/Pagination";
import DocumentCard from "./DocumentCard";
import DocumentFillterning from "./DocumentFillterning";
import OpenDocSignUrlPopup from "../../Modal/OpenDocSignUrlPopup";
import ViewDescriptionPopup from "./ViewDescriptionPopup";
import CustomePagination from "../../../hooks/CustomePagination";

function ReceivedDocuments({ activeTab }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const [openIndex, setOpenIndex] = useState(-1);
  const tableRef = useRef();
  const dispatch = useDispatch();
  const [viewProject, setViewProject] = useState(false);
  const [viewDoc, setViewDoc] = useState(false);
  const [showSignDoc, setShowSignDoc] = useState(false);
  const [signUrl, setSignUrl] = useState("");
  const { authUser } = useSelector((state) => state);
  const [totalRows, setTotalRows] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [showDescPopup, setShowDescPopup] = useState(false);
  const [WorkFlowDetails, setWorkFlowDetails] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [forApproval, setForApproval] = useState(null);
  const [projects, setProjects] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sortIndex, setSortIndex] = useState(-1);
  const [listingData, setListingData] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [selectedStartdate, setSelectedStartdate] = useState("");
  const [selectedEnddate, setSelectedEnddate] = useState("");

  const [filterVal, setFilterVal] = useState({
    searchKey: "",
    startDate: "",
    endDate: "",
    pageNo: 1,
    perPage: 10,
    projectIds: "",
    // requestType: "Send",
    status: "",
  });

  const handleFilterChange = (e, name) => {
    setFilterVal({
      ...filterVal,
      [name]: e.target.value,
      pageNo: filterVal.pageNo,
    });
  };

  const handleOndatechange = (e, name) => {
    setFilterVal({ ...filterVal, [name]: e, pageNo: filterVal.pageNo });
  };

  const handlePageChange = (page, value) => {
    setFilterVal({ ...filterVal, pageNo: value });
  };
  const handlePerRowsChange = async (e) => {
    setFilterVal({ ...filterVal, perPage: e.target.value });
  };

  const handlePerPagesChange = async (perPageLimit) => {
    setFilterVal({ ...filterVal, perPage: perPageLimit });
  };

  // filter
  const startDateFilter = (event) => {
    setSelectedStartdate(event);
    fetchListingData(event, "startDate");
  };
  const endDateFilter = (event) => {
    setSelectedEnddate(event);
    fetchListingData(event, "endDate");
  };

  // const handleCallDocSign = async (
  //   documents,
  //   contractName,
  //   userReminderTime,
  //   workFlowId
  // ) => {
  //   return Promise.all(
  //     documents?.map(async (ele) => {
  //       return await sendDocSignRequest({
  //         pdfurl: ele,
  //         contractName,
  //         userReminderTime: userReminderTime || "10",
  //         workFlowId,
  //       });
  //     })
  //   );
  // };

  // const handleClickDocument = async (ele) => {
  //   const isRead = Array.isArray(ele?.readBy)
  //     ? ele?.readBy?.length > 0
  //     : ele?.readBy;
  //   const allDoc = ele?.attachments;
  //   dispatch(SetloaderData(true));
  //   if (
  //     ele?.docMasterId?.isDigitalSignatureRequire &&
  //     !isRead &&
  //     allDoc?.length > 0
  //   ) {
  //     const data = await handleCallDocSign(
  //       allDoc,
  //       ele?.docMasterId?.documentType,
  //       10,
  //       ele?._id
  //     );
  //     setProjects(ele);
  //     setViewDoc(true);
  //     setTimeout(() => {
  //       fetchListingData();
  //     }, 500);
  //   } else {
  //     setProjects(ele);
  //     setViewDoc(true);
  //   }
  //   dispatch(SetloaderData(false));
  // };
  const checkReadByUser = (obj) => {
    const status = Array.isArray(obj?.readBy)
      ? obj?.readBy?.some((ele) => ele?.user_id === authUser?.data?._id)
      : obj?.readBy;
    return status;
  };
  const fetchListingData = async () => {
    try {
      let url = `${apiURl.getAllDocuments}`;

      if (activeTab === "RECEIVED") {
        url = `${url}/ReceivedDocuments`;
      } else if (activeTab === "REQUESTED") {
        url = `${url}/RequestedDocuments`;
      } else {
        url = `${url}/MyDocuments`;
      }

      const searchString = makeSearchString(filterVal);
      url = searchString ? `${url}?${searchString}` : url;
      dispatch(SetloaderData(true));
      const data = await API({
        base_url: BASECONFIG.REACT_APP_DOCUMENT_BASEURL_V2,
        url: `${url}`,
        method: "GET",
        dispatch,
      });

      if (data?.status) {
        setData(data);
        setListingData(data?.data);
        dispatch(SetloaderData(false));
        setTotalRows(
          Math.ceil(Number(data?.totalCount) / Number(data?.perPage))
        );
        setTotalCount(Number(data?.totalCount));
      } else {
        dispatch(SetloaderData(false));
        setData({});
      }
    } catch (error) {
      dispatch(SetloaderData(false));
      toast.error(error.message);
    } finally {
    }
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    } else {
      fetchListingData();
    }
  }, [isMounted, filterVal, activeTab]);

  const userCreatedAt = (item) => {
    let arr = Array.isArray(item?.contract?.contractInstances)
      ? item?.contract?.contractInstances?.filter((x) => x?.participants?.find((ele)=>(ele?.userId?._id === authUser?.data?._id)))
      : [];
    let date = arr?.length > 0 ? arr[arr?.length - 1]?.sentAt : item?.createdAt;
    return moment.utc(date).local().format("DD/MM/YYYY hh:mm a");
  };

  const sortListings = (sortTitle, sType) => {
    const sortFunctions = {
      Date: (a, b) => moment(a?.createdAt) - moment(b?.createdAt),
      Document_Type: (a, b) =>
        a?.docMasterId?.documentType?.localeCompare(
          b?.docMasterId?.documentType
        ),
      Subject: (a, b) => a?.subject.localeCompare(b?.subject),
      Renewal: (a, b) =>
        a?.docMasterId?.renewal?.period?.localeCompare(
          b?.docMasterId?.renewal?.period
        ),
    };
    let newListingArr = [...listingData];
    const sortFn = sortFunctions[sortTitle];
    if (sortFn) {
      if (sType === "asc") {
        newListingArr.sort(sortFn);
      } else if (sType === "des") {
        newListingArr.sort((a, b) => sortFn(b, a));
      }
    }
    setListingData(newListingArr);
  };

  const fetchWorkFlowData = (item) => {
    setWorkFlowDetails(true);
    navigate(`/managedocument/${item?.workflowType}/document/${activeTab}/${item?._id}`);
  };
  const approvalCheckByUser = selectData?.approval?.filter(
    (ele) => ele?.user_id === authUser?.data?._id
  );

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenIndex(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {viewProject ? (
        <AllProjectsList
          show={viewProject}
          handleClose={() => {
            setViewProject(false);
            setProjects([]);
          }}
          data={projects}
        />
      ) : (
        ""
      )}
      {showSignDoc ? (
        <OpenDocSignUrlPopup
          show={showSignDoc}
          handleClose={() => {
            setShowSignDoc(false);
            setProjects([]);
          }}
          data={projects}
          url={signUrl}
        />
      ) : (
        ""
      )}
      {viewDoc ? (
        <AllDocumentList
          show={viewDoc}
          handleClose={() => {
            setViewDoc(false);
            setProjects([]);
          }}
          data={projects}
          callBackFunction={fetchListingData}
          handleOpenSignUrl={(url) => {
            setSignUrl(url);
            setShowSignDoc(true);
          }}
        />
      ) : (
        ""
      )}
      {showPopup ? (
        <AddCommentPopup
          show={showPopup}
          selectData={selectData}
          handleClose={() => {
            setShowPopup(false);
            setSelectData(null);
            setForApproval(false);
          }}
          callBackFunction={fetchListingData}
          forApproval={forApproval}
        />
      ) : (
        ""
      )}
      {showDescPopup ? (
        <ViewDescriptionPopup
          show={showDescPopup}
          selectData={selectData}
          handleClose={() => {
            setShowDescPopup(false);
            setSelectData(null);
          }}
          callBackFunction={fetchListingData}
        />
      ) : (
        ""
      )}

      <DocumentCard documentType={"Received"} />

      <div className="row card justify-content-end">
        {/* <div className="col-md-4 col-xs-12 mt-2">

          <div className="form-group">
            <Select
              name="projectIds"
              options={allProjects?.map((ele) => ({
                ...ele,
              }))}
              isClearable
              isMulti
              placeholder="Select Project"
              getOptionLabel={(options) => `${options["title"]}`}
              getOptionValue={(options) => options["_id"]}
              value={
                allProjects?.length > 1 &&
                allProjects?.filter((ele) =>
                  filterVal?.projectIds?.split(",")?.includes(ele._id)
                )
              }
              onChange={(event) => {
                let _idArr = event?.map((ele) => ele._id)?.join(",");
                setFilterVal({
                  ...filterVal,
                  projectIds: _idArr,
                });
              }}
            />
          </div>
        </div> */}
        <DocumentFillterning
          documentFillter={"Received"}
          handleOnchange={handleFilterChange}
          value={filterVal}
          handleOndatechange={handleOndatechange}
        />

        <div className="card-content">
          <div className="responsiveTable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="tableHeading">
                    Date&Time
                    {sortIndex === 1 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Date",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(1);
                          setSortType("asc");
                          sortListings("Date", "asc");
                        }}
                      />
                    )}{" "}
                  </th>

                  <th scope="col" className="tableHeading">
                    Document Type
                    {sortIndex === 2 ? (
                      sortType === "asc" ? (
                        <FaArrowUp
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Document_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowDown
                          onClick={() => {
                            setSortType(sortType === "asc" ? "des" : "asc");
                            sortListings(
                              "Document_Type",
                              sortType === "asc" ? "des" : "asc"
                            );
                          }}
                          className="mx-1"
                          style={{ cursor: "pointer", fontSize: "10px" }}
                        />
                      )
                    ) : (
                      <img
                        className="mx-1"
                        src={"../Image/shotBy.svg"}
                        alt=""
                        onClick={() => {
                          setSortIndex(2);
                          setSortType("asc");
                          sortListings("Document_Type", "asc");
                        }}
                      />
                    )}{" "}
                  </th>

                  <th scope="col" className="tableHeading">
                    Reference
                  </th>
                  <th scope="col" className="tableHeading">
                    Actions
                  </th>
                  <th scope="col" className="tableHeading">
                    More Actions
                  
                  </th>
                </tr>
              </thead>

              <tbody>
                {listingData?.length > 0 ? (
                  listingData?.map((row, index) => {
                    return (
                      <tr className="borderLine">
                        <td valign="middle">
                          {/* {row.createdAt
                            ? moment(row.createdAt)
                                .local()
                                .format("DD/MM/YYYY hh:mm a")
                            : ""} */}
                          {userCreatedAt(row)}
                        </td>

                        <td valign="middle">
                          {row?.documents?.length > 0 &&
                            row?.documents?.map((ele) => ele?.subject)}
                          <a>
                            {row?.permissions?.isDigitalSignRequired && (
                              <FaFileSignature
                                color="blue"
                                title="digital signature require"
                                className="ms-2"
                                size={15}
                              />
                            )}
                            <i class="fa fa-download modalDocument pointer mr-1" aria-hidden="true"></i>
                            <i class="fa fa-eye modalDocument pointer ml-1" aria-hidden="true" onClick={()=>fetchWorkFlowData(row)}></i>
                          </a>
                        </td>
                        <td valign="middle">
                          <p>{row?.project?.length > 0 && row?.project?.map((ele_project)=>(ele_project?.projectDetails?.title)) || '---'}</p>
                        </td>
                       
                       

                        <td valign="middle">
                          {row?.attachments?.length > 0 ? (
                            <span
                              className="text-primary "
                              onClick={() => {
                                if (
                                  authUser?.data?.userType?.isIssuer &&
                                  row?.isProjectWorkflow
                                ) {
                                  fetchWorkFlowData(row);
                                } else {
                                  setProjects(row);
                                  // if (
                                  //   row?.docMasterId?.isDigitalSignatureRequire
                                  // ) {
                                  //   setShowSignDoc(true);
                                  // } else {
                                  // }
                                  setViewDoc(true);
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {/* DS Not require and required both case document should be show  */}
                              {/* {row?.isDigitalSignRequired ||
                              row?.docMasterId?.isDigitalSignatureRequire ? ( */}
                              <a>
                                Click here
                                {(row?.docMasterId?.isDigitalSignatureRequire ||
                                  row?.isDigitalSignRequired) && (
                                  <FaFileSignature
                                    color="blue"
                                    title="digital signature require"
                                    className="ms-2"
                                    size={15}
                                  />
                                )}
                              </a>
                              {/* // ) : (
                              //   ""
                              // )} */}
                              {/* {row?.attachments?.[0]
                                ?.split(".")
                                ?.pop()
                                ?.toLowerCase()} */}
                              {row?.attachments?.length > 1 ? (
                                <span>+{row?.attachments?.length - 1} </span>
                              ) : (
                                ""
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>

                     
                        <td valign="middle">
                          <>
                            <div
                              className="div-wrapper customeActionButton text-left"
                              onClick={() =>
                                setOpenIndex(openIndex === index ? -1 : index)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src="../Image/group1171276003.png"
                                alt=""
                                style={{ maxWidth: "30px" }}
                              />
                              {/* <BsThreeDotsVertical size={25} className="actionDot" /> */}

                              <div className="faEllipsisVertical customeEllipsisVertical">
                                <div
                                  className="divOpenClose"
                                  onClick={() =>
                                    setOpenIndex(
                                      openIndex === index ? -1 : index
                                    )
                                  }
                                >
                                  {/* satya */}

                                  {openIndex === index && (
                                    <div
                                      ref={ref}
                                      style={{ padding: "0px 10px" }}
                                    >
                                      {/* {row?.isNoActionAllowed ? (
                                      <Link to={`#`}>
                                        No Action Allowed
                                        <br />
                                      </Link>
                                    ) : (
                                      ""
                                    )} */}
                                      <Link
                                        to={"#"}
                                        onClick={() => {
                                          setSelectData(row);
                                          setShowDescPopup(true);
                                        }}
                                      >
                                        View Description
                                        <br />
                                      </Link>
                                      {row?.isCommentAllowed ? (
                                        <Link
                                          to={"#"}
                                          onClick={() => {
                                            setSelectData(row);
                                            setShowPopup(true);
                                          }}
                                          className={
                                            row?.comments?.length === 0
                                              ? ""
                                              : "text-secondary"
                                          }
                                        >
                                          Add/View Comment
                                          <br />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                      {row?.isApprovalAllowed ? (
                                        <Link
                                          to={`#`}
                                          onClick={() => {
                                            setSelectData(row);
                                            setShowPopup(true);
                                            setForApproval(true);
                                          }}
                                          className={
                                            approvalCheckByUser?.length === 0
                                              ? "text-primary"
                                              : "text-secondary"
                                          }
                                        >
                                          Approve/Reject
                                          <br />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                      {row?.isReadReceiptAllowed ? (
                                        <Link
                                          to={`#`}
                                          onClick={() => {
                                            const isRead = checkReadByUser(row);
                                            if (!isRead) {
                                              setProjects(row);
                                              setViewDoc(true);
                                            }
                                          }}
                                          className={
                                            !checkReadByUser(row)
                                              ? ""
                                              : "text-secondary"
                                          }
                                        >
                                          Send Read Receipt
                                          <br />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                      {!row?.isApprovalAllowed &&
                                        !row?.isCommentAllowed &&
                                        !row?.isNoActionAllowed &&
                                        !row?.isReadReceiptAllowed && (
                                          <Link to={`#`}>
                                            No Action Allowed
                                            <br />
                                          </Link>
                                        )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colspan="8" className="text-center text-danger">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CustomePagination
        handlePageChange={handlePageChange}
        currentPage={filterVal.pageNo}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        perPage={filterVal.perPage}
        totalCount={totalCount}
      />
    </>
  );
}

export default ReceivedDocuments;
