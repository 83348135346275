/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaArrowAltCircleDown } from "react-icons/fa";
import {
  validateRequire,
  isValid,
  downLoadLink,
} from "../../Validation/InputValidation";
import { useDispatch, useSelector } from "react-redux";
import { SetloaderData } from "../../store/reducer";
import { apiURl } from "../../store/actions";
import { API, getAwsImageUrl, sendDocSignRequest } from "../../apiwrapper";
import { BASECONFIG } from "../../config";
import { toast } from "react-toastify";
import { validationMessages } from "../../store/actions/api-url";
import moment from "moment";
import { useEffect } from "react";
function AllDocumentList({
  show,
  handleClose,
  data = {},
  callBackFunction = () => {},
  handleOpenSignUrl = () => {},
  workflow_id,
  callBackStatusApi = () => {}
}) {
  console.log(data,'datadatadata')
  let arr = data?.attachments || data?.document || [];
  const { Loader, authUser } = useSelector((state) => state);
  var isRead = Array.isArray(data?.readBy)
    ? data?.readBy?.some((ele) => ele?.user_id === authUser?.data?._id)
    : data?.readBy;
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [signedDoc, setSignedDoc] = useState([]);

  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showSignDoc, setShowSignDoc] = useState({ show: false, url: "" });

  let [inpField, setInpField] = useState({ documents: "", remarks: "" });

  let { remarks = "", documents = "" } = inpField;

  const handleChange = (e) => {
    setInpField({ ...inpField, [e.target.name]: e.target.value });
    handleValidate(e);
  };

  const handleValidate = (e) => {
    const errors1 = {};
    switch (e.target.name) {
      case "documents":
        errors1.documents = validateRequire(e.target.value);
        break;
      default:
        break;
    }
    setErrors(errors1);
  };

  const validateAll = () => {
    let err1 = {};
    err1.documents = validateRequire(documents);
    return err1;
  };
  const resetInputField = () => {
    setInpField({
      remarks: "",
      documents: "",
    });
  };
  const handleGetDigitalSignUrl = async (e, docUrl) => {
    e.preventDefault();
    try {
      const payload = {
        documents: docUrl,
        userName: `${authUser?.data?.personalInfo?.name?.firstName} ${authUser?.data?.personalInfo?.name?.lastName}`,
        email: `${authUser?.data?.personalInfo?. contact?. email?.id}`,
        remarks: remarks,
        workflow_id: data?._id? data?._id :workflow_id,
        user_id:authUser?.data?._id
      };  
      dispatch(SetloaderData(true));   
      const url = `${apiURl.getSignUrl}`;
      const resp = await API({
        base_url: BASECONFIG.REACT_APP_BASEURL_V2,
        url: `${url}`,
        method: "POST",
        dispatch,
        body: { ...payload },
      });
      if (resp?.status || resp?.status === true) {
        dispatch(SetloaderData(false));
        toast.success(resp?.message);
        const wfUrl = resp?.data?.signerdetail?.[0]?.workflowUrl;
        handleOpenSignUrl(wfUrl);
        handleClose();
      }
    } catch (error) {
      dispatch(SetloaderData(false));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let submitButton = document.getElementById("submit");
    submitButton.disabled = true;
    dispatch(SetloaderData(true));
    try {
      let err = validateAll();
      if (isValid(err)) {
        let docUrl = await getAwsImageUrl(inpField?.documents);
        let payload = { ...inpField, documents: docUrl };
        let url = `${apiURl.updateUser}/work/sent/${data?._id}`;
        const resp = await API({
          base_url: BASECONFIG.REACT_APP_BASEURL_V2,
          url: `${url}`,
          method: "PUT",
          dispatch,
          body: { ...payload },
        });

        if (resp?.status || resp?.status === "true") {
          toast.success(resp?.message);
          if (
            data?.isDigitalSignRequired || data?.isDigitalSignatureRequire || 
            data?.docMasterId?.isDigitalSignatureRequire
          ) {
            handleGetDigitalSignUrl(e, docUrl);
          } else {
            setTimeout(() => {
              callBackFunction();
            }, 500);
            handleClose();
          }
          resetInputField();
        } else {
          toast.warn(resp.message);
        }
      } else {
        setErrors(err);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      submitButton.disabled = false;
      dispatch(SetloaderData(false));
    }
  };

  const handleCheckDocSignStatus = async () => {
    const email = data?.contractId?.find(
      (ele) => ele?.email
    )?.email;
    
    let payload = {
      email: authUser?.data?.userType?.isInvestor ? authUser?.data?.personalInfo?. contact?. email?.id : email,
      workflow_id: data?._id? data?._id :workflow_id,
    };
    dispatch(SetloaderData(true));
    try {
      let url = `${apiURl.getSignStatus}`;
      const resp = await API({
        base_url: BASECONFIG.REACT_APP_DOCUMENT_BASEURL_V2,
        url: `${url}`,
        method: "POST",
        dispatch,
        body: { ...payload },
      });
      if (resp.status === true) {
        dispatch(SetloaderData(false));
        setSignedDoc(resp?.data);
      }else{
        dispatch(SetloaderData(false));
      }
    } catch (error) {
      dispatch(SetloaderData(false));
    }
  };

  const handleClickDownload = async (docUrl) => {
    if (data?.isReadReceiptAllowed && !isRead) {
      setShowCheckBox(true);
      setInpField({ ...inpField, selectedUrl: docUrl, isChecked: false });
    } else if (
      (data?.isDigitalSignRequired ||
        data?.docMasterId?.isDigitalSignatureRequire) &&
      !signedDoc?.isCompleted
    ) {
      if(data?.signContract === ""){
        toast.error('The system failed to load the document, please inform support!')
        return
      }else {
        handleOpenSignUrl(data?.signContract);
        handleClose();
      }
    } else {
      downLoadLink(docUrl, data?.subject);
    }
  };

  const handleRead = async (e) => {
    e.preventDefault();
    if (!inpField?.isChecked) {
      setErrors({ ...errors, isChecked: validationMessages?.require });
      return;
    }
    let submitButton = document.getElementById("submit");
    submitButton.disabled = true;
    dispatch(SetloaderData(true));
    try {
      let url = `${apiURl.updateUser}/work/view/${data?._id}`;
      const resp = await API({
        base_url: BASECONFIG.REACT_APP_BASEURL_V2,
        url: `${url}`,
        method: "GET",
        dispatch,
      });

      if (resp?.status || resp?.status === "true") {
        toast.success(resp?.message);
        if (
          data?.requestType !== "Request" &&
          (data?.isDigitalSignRequired ||
            data?.docMasterId?.isDigitalSignatureRequire)
        ) {
          handleOpenSignUrl(data?.signContract);
          handleClose();
        } else {
          downLoadLink(inpField?.selectedUrl, data?.subject);
        }
        callBackFunction();
      } else {
        toast.warn(resp.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      submitButton.disabled = false;
      dispatch(SetloaderData(false));
    }
  };
  function getAttachments(arr, handleClickDownload, data) {
    return (
      <>
        {/* {arr?.length > 0 ? ( */}
          <>
            <div className="responsiveTable">
            <Table striped bordered hover>
              <thead className="">
                <tr>
                  {/* <th className="">Sr. No</th> */}
                  <th className="">Document Type</th>
                  {data?.isSigned  ? (
                    <>
                      <th className="">Signed Contract</th>
                    </>
                  ) : (
                    <>
                      <th className="">Download</th>
                    </>
                  )}
                  {(data?.permissions?.isDigitalSignRequired || signedDoc?.isCompleted) && (
                    <>
                      <th className=""> Transcript</th>
                      <th className="">Sign Status </th>
                    </>
                  )}
                  {data?.permissions?.isReadReceiptAllowed && (
                    <th className="">Read Status </th>
                  )}
                </tr>
              </thead>
              <tbody className="">
                {/* {arr?.map((ele, index) => ( */}
                  <tr>
                    {/* <td>{index + 1 + "."}</td> */}
                 
                    <td>
                   
                      {data?.documents?.length > 0 && data?.documents?.map((ele)=>ele?.subject) || '---'}
                    </td>

                    <td className="">
                      <i
                        class="fa fa-download modalDocument"
                        aria-hidden="true"
                        onClick={() => {
                          const docLink = data?.isSigned 
                            ? signedDoc?.finalSignedContract
                            : '';
                          handleClickDownload(docLink);
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>

                      {data?.isSigned  ? (
                        <button
                          className="btn"
                          onClick={() => {
                            setShowSignDoc({
                              show: true,
                              url: signedDoc?.finalSignedContract,
                            });
                          }}
                        >
                          View
                        </button>
                      ) : (
                        ""
                      )}
                    </td>

                    {(data?.permissions?.isDigitalSignRequired) && (
                      <>
                        <td>
                          {" "}
                          {data?.isSigned  ? (
                            <>
                              <i
                                class="fa fa-download modalDocument"
                                aria-hidden="true"
                                onClick={() => {
                                  downLoadLink(
                                    signedDoc?.auditCertificateUrl,
                                    `${data?.documents?.length > 0 && data?.documents?.map((ele)=>ele?.subject)}_transcript`
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                              <button
                                className="btn"
                                onClick={() => {
                                  setShowSignDoc({
                                    show: true,
                                    url: signedDoc?.auditCertificateUrl,
                                  });
                                }}
                              >
                                View
                              </button>
                            </>
                          ) : (
                            "--"
                          )}
                        </td>

                        <td>{data?.isSigned ? "Signed" : "--"}</td>
                      </>
                    )}
                    {data?.permissions?.isReadReceiptAllowed && (
                      <td>{data?.isRead ? "Read" : "--"}</td>
                    )}
                  </tr>
                {/* // ))} */}
              </tbody>
            </Table>
            </div>
          </>
        {/* // ) : (
        //   <>""</>
        // )} */}
      </>
    );
  }
// open new sign document modal
  const sendDocumentUsertest = (item) => {
    return item?.findIndex((ele) => (ele?.user_id === authUser?.data?._id  ) ) == -1 ? true : false;
    // case 1 : user id === auth user ele?.user_id === authUser?.data?._id && isSigned === true open Send Document
    // case 2 : ele?.user_id === authUser?.data?._id && isSigned === false handleCheckDocSignStatus()
    // case 3: ele?.user_id === authUser?.data?._id == -1 open uplaod modal
  }

  const sendDocumentUser = (item) => {
    const userIndex = item?.findIndex((ele) => ele?.user_id === authUser?.data?._id);
    if (userIndex === -1) {
      // Case 3: No matching user_id found
      // Open upload modal
      return true;
    } else {
      const user = item[userIndex];
      if (user?.isSigned) {
        // Case 1: User ID matches and isSigned is true
        // Open Send Document
        return false;
      } else {
        // Case 2: User ID matches and isSigned is false
        // Handle Check Doc Sign Status
        // handleCheckDocSignStatus();
        return false;
      }
    }
  }
  







  useEffect(() => {
    if (
      
      (data?.isDigitalSignRequired || data?.isDigitalSignatureRequire ||
        data?.workflowData?.docMasterId?.isDigitalSignatureRequire  || data?.docMasterId?.isDigitalSignatureRequire ) &&
      (arr?.length > 0)
    ) {
      
      // handleCheckDocSignStatus();
    }
  }, [data?.sendDocByUser?.length]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => (callBackStatusApi(data?._id),handleClose())}
        backdrop="static"
        keyboard={false}
        style={{ backdropFilter: "blur(5px)" }}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Document
            {/* <b className="text-capitalize">
              {data?.docMasterId?.documentType
                ? data?.docMasterId?.documentType
                : ""}{" "}
            </b>{" "} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-2">
            {showSignDoc?.show ? (
              <div className={" container"}>
                <div className={" signZy-iFrame "} style={{ height: "32rem" }}>
                  <iframe
                    src={showSignDoc?.url}
                    className={`my-2`}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                    title="doc-signZy-iFrame"
                  />
                </div>
              </div>
            ) : showCheckBox ? (
              <>
                <div className="mb-3">
                  <div className="termsConditionSignInpage">
                    <input
                      type="checkbox"
                      id="isChecked"
                      name="isChecked"
                      value={inpField?.isChecked}
                      onClick={(e) => {
                        setInpField({
                          ...inpField,
                          isChecked: e.target.checked,
                        });
                        setErrors({});
                      }}
                      className="checkBoxCustome"
                    />
                    <label htmlFor="isChecked" style={{ marginLeft: "10px" }}>
                      {" "}
                      I read <span className="text-danger"> *</span>
                    </label>
                  </div>
                  <span className="text-danger">{errors.isChecked}</span>
                </div>
              </>
            ) : (
              <>
              {/* // for new sign document */}
                {(data?.requestType === "Request" &&
                sendDocumentUser(data?.sendDocByUser) ) ? (
                  <>
                    <div className="col-12">
                      <div className="form-group ">
                        <label className="mb-2">Document</label>
                        <input
                          type="file"
                          className="form-control"
                          name="documents"
                          multiple={false}
                          accept=".pdf,image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              var extension = file.type
                                .split("/")
                                .pop()
                                .toLowerCase();
                              const isValidFile = [
                                "pdf",
                                "jpg",
                                "jpeg",
                                "gif",
                                "png",
                                "image",
                              ].some((item) => extension.includes(item));

                              if (isValidFile) {
                                let event = {
                                  target: {
                                    name: "documents",
                                    value: e.target.files[0],
                                  },
                                };
                                handleChange(event);
                              } else {
                                e.target.value = "";
                                let event = {
                                  target: {
                                    name: "documents",
                                    value: e.target.files[0],
                                  },
                                };
                                handleChange(event);
                                setErrors({
                                  ...errors,
                                  documents:
                                    "File type is not supported, please upload an image or pdf.",
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      {errors?.documents ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {errors?.documents}
                        </span>
                      ) : (
                        ""
                      )}
                      {documents && typeof documents === "string" ? (
                        <a
                          href={documents}
                          target="_blank"
                          className="my-1"
                          download={true}
                        >
                          <img src={"../Image/uploadTypeImage.png"} />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className=" col-12 mt-2">
                      <div className="form-group ">
                        <label className="mb-2">Remarks</label>
                        <textarea
                          type="text"
                          value={remarks}
                          rows={4}
                          cols={4}
                          className="form-control"
                          placeholder="Enter remarks."
                          name="remarks"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {(data?.sendDocByUser?.length > 0) ? (
                      <>
                        <p>
                          {data?.requestType === "Request"
                            ? "Send"
                            : "Digital Signed"}{" "}
                          Document
                        </p>
                        <hr />
                        <div className="responsiveTable">
                        <Table striped bordered hover className="table">
                          <thead className="">
                            <tr>
                              <th>Sr.</th>
                              <th>Date</th>
                              <th>Document Type</th>
                              {signedDoc?.isCompleted ? (
                                <>
                                  <th className="">Signed Contract</th>
                                </>
                              ) : (
                                <>
                                  <th className="">Download</th>
                                </>
                              )}
                              {(data?.isDigitalSignRequired || data?.isDigitalSignatureRequire ) && (
                                <>
                                  <th className=""> Transcript</th>
                                  <th className="">Sign Status </th>
                                </>
                              )}

                              <th>Remarks</th>
                              {/* <th>Status</th> */}
                            </tr>
                          </thead>
                          <tbody className="">
                            {data?.sendDocByUser?.map((ele, index) => (
                              <tr>
                                <td>{index + 1 + "."}</td>
                                <td>
                                  {" "}
                                  {ele.date
                                    ? moment(ele.date)
                                        .local()
                                        .format("DD/MM/YYYY hh:mm a")
                                    : ""}
                                </td>
                                <td>
                                  {/* <img
                                    src={
                                      ele?.documents
                                        ?.split(".")
                                        ?.pop()
                                        ?.toLowerCase() === "pdf"
                                        ? "https://almabrook-dev-backend.s3.us-east-1.amazonaws.com/alm/image_1704448477472.jpg"
                                        : ele?.documents
                                    }
                                    width={25}
                                    height={25}
                                    className="rounded-circle mx-2"
                                  />
                                  {ele?.documents
                                    ?.split(".")
                                    ?.pop()
                                    ?.toLowerCase()} */}
                                    {data?.documentType|| data?.subject}

                                </td>
                                <td className="">
                                  <i
                                    class="fa fa-download modalDocument"
                                    aria-hidden="true"
                                    onClick={() => {
                                      const docLink = signedDoc?.isCompleted
                                        ? signedDoc?.finalSignedContract
                                        : ele?.documents;
                                      downLoadLink(docLink);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  ></i>

                                  {signedDoc?.isCompleted ? (
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        setShowSignDoc({
                                          show: true,
                                          url: signedDoc?.finalSignedContract,
                                        });
                                      }}
                                    >
                                      View
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>

                                {(data?.isDigitalSignRequired || data?.isDigitalSignatureRequire) && (
                                  <>
                                    <td>
                                      {" "}
                                      {signedDoc?.isCompleted ? (
                                        <>
                                          <i
                                            class="fa fa-download modalDocument"
                                            aria-hidden="true"
                                            onClick={() => {
                                              downLoadLink(
                                                signedDoc?.auditCertificateUrl,
                                                `${data?.subject}_transcript`
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          ></i>
                                          <button
                                            className="btn"
                                            onClick={() => {
                                              setShowSignDoc({
                                                show: true,
                                                url: signedDoc?.auditCertificateUrl,
                                              });
                                            }}
                                          >
                                            View
                                          </button>
                                        </>
                                      ) : (
                                        "--"
                                      )}
                                    </td>

                                    <td>
                                      {signedDoc?.isCompleted ? "Signed" : "--"}
                                    </td>
                                  </>
                                )}

                                <td className="">{ele?.remarks}</td>
                                {/* <td className="">{ele?.status}</td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        </div>
                      </>
                    ) : (
                      getAttachments(arr, handleClickDownload, data)
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 text-end mt-2">
            {(!showCheckBox &&
              data?.requestType === "Request" &&
              sendDocumentUser(data?.sendDocByUser)) && (
                <Button
                  className="btn btn-primary me-1 ml-2  btn-sm"
                  id="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={Loader?.data || false}
                >
                  {Loader?.data ? <Spinner size="sm" /> : "OK"}
                </Button>
              )}
            {showCheckBox && (
              <Button
                className="btn btn-primary me-1 ml-2  btn-sm"
                id="submit"
                onClick={(e) => {
                  handleRead(e);
                }}
                disabled={Loader?.data || false}
              >
                {Loader?.data ? <Spinner size="sm" /> : "OK"}
              </Button>
            )}
            <Button
              type="btn"
              onClick={() => {
                if (showSignDoc?.show) {
                  setShowSignDoc({ show: false, url: "" });
                } else if (showCheckBox) {
                  setShowCheckBox(false);
                } else {
                  handleClose();
                }
              }}
              className="btn btn-primary btn-sm"
            >
              {showCheckBox || showSignDoc?.show ? "Back" : "Close"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllDocumentList;
